import React from "react"
import AOS from "aos"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"


// const getBrowserLanguage = () => {
//     if (typeof navigator === `undefined`) {
//         return "en"
//     }
//
//     const lang = navigator && navigator.language && navigator.language.split("-")[0]
//
//     if (!lang) return "en"
//
//     if (!["es", "en"].includes(lang)) {
//         return "en"
//     }
//
//     return lang
// }

export const onClientEntry = (props) => {
    AOS.init({
        once: true
    });

    if (AOS) {
        AOS.refresh();
    }

    console.log("gatsby-browser onClientEntry");

    /*window.onload = () => {
        const browserLang = getBrowserLanguage()
        let languageChecked = getCookie("language-checked")

        if (languageChecked == null) {
            languageChecked = browserLang
            setCookie("language-checked", browserLang, new Date("01-01-2100"))
        }

        if (typeof window !== "undefined" && lang !== languageChecked) {
            const slug = languageChecked === "en" ? "/" : `/${languageChecked}`

            setTimeout(() => navigate(slug))
        }
    }*/
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (typeof window !== "undefined") {
        window.HubSpotConversations?.widget?.refresh()
        window.HubSpotCallsToActions?.refresh()
    }
}

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}


    
